import {
    Button,
    Form,
    Input,
    Select,
    Alert
} from 'antd';
import { useState } from 'react';
import {APIRestPassword} from "../../helpers/APIs/UserAPIs";
const { Option } = Select;
const residences = [
    {
        value: 'zhejiang',
        label: 'Zhejiang',
        children: [
            {
                value: 'hangzhou',
                label: 'Hangzhou',
                children: [
                    {
                        value: 'xihu',
                        label: 'West Lake',
                    },
                ],
            },
        ],
    },
    {
        value: 'jiangsu',
        label: 'Jiangsu',
        children: [
            {
                value: 'nanjing',
                label: 'Nanjing',
                children: [
                    {
                        value: 'zhonghuamen',
                        label: 'Zhong Hua Men',
                    },
                ],
            },
        ],
    },
];
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const ResetModal = ({onCancel}) => {
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [form] = Form.useForm();
    const onFinish = async(values) => {
        console.log('Received values of form: ', values);
       const res=await APIRestPassword(values.oldPassword,values.password,values.confirm)
            console.log('password reset response on view',res);
        if(res==='success'){
            setAlertVisible(true)
            setAlertMessage('Password Changed Successfully')
            setAlertType('success')
            onCancel();
        }else if(res==='missMatch'){
            setAlertVisible(true)
            setAlertMessage('Old Password is Wrong')
            setAlertType('error')
        }else if(res==='validation'){
            setAlertVisible(true)
            setAlertMessage('Incorrect Data')
            setAlertType('warning')
        }else {
            setAlertVisible(true)
            setAlertMessage('Internal Server Error')
            setAlertType('error')
        }

    };
    const handleClose = () => {
        setAlertVisible(false);
    };

    return(<Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{ residence: ['zhejiang', 'hangzhou', 'xihu'], prefix: '86' }}
        style={{ maxWidth: 600 }}
        scrollToFirstError
    >
        {alertVisible && (<Alert message={alertMessage} type={alertType} closable afterClose={handleClose} style={{marginBottom:'10px'}}/>)}
        <Form.Item
            name="oldPassword"
            label="Old Password"
            rules={[
                {
                    required: true,
                    message: 'Please input your Old password!',
                },
            ]}
            hasFeedback
        >
            <Input.Password />
        </Form.Item>
        <Form.Item
            name="password"
            label="Password"
            rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
            ]}
            hasFeedback
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                }),
            ]}
        >
            <Input.Password />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>);
}
export default ResetModal
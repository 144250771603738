import React, {useContext,useEffect, useState} from "react";
import AuthLayout from "../../Layout/AuthLayout";
import langContext from "../../helpers/Context/lang-context";
import {Space, Table, Button, Card, DatePicker, Divider, Form, Input, Select, Tag} from 'antd';
import {ColumnsType} from 'antd/es/table';
import {
    addCommasToNumber,
    APIDownloadUsertFile,
    APIUser,
    makeServerDate
} from "../../helpers/APIs/UserAPIs";
import {useNavigate, useSearchParams} from 'react-router-dom'
import dayjs from 'dayjs';
import userContext from "../../helpers/Context/user-context";

const {RangePicker} = DatePicker;
const Users = () => {
    const [dataSourse, setDataSourse] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [pageSize, setPageSize] = useState('10');
    const [page, setPage] = useState(1);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [date, setDate] = useState([]);
    const [userType, setUserType] = useState('all');
    const [userAction, setUserAction] = useState('all');
    const [searchParams, setSearchParams] = useSearchParams();
    const ctx_user=useContext(userContext)
    const ctx=useContext(langContext)
    const t=ctx.t;
    const navigate = useNavigate()


    const fetchRecords = async (obj) => {
        console.log("user page rerender")
        setLoading(true)
        const response = await APIUser(obj);
        if(response){
            if (response == "TOKEN_EXPIRED") {
                localStorage.removeItem('token_gasv')
                ctx_user.setUser('');
                navigate('/login');
            }else{
                setTotalCount(response.totalCount);
                if(response.players){
                    setDataSourse(response.players.data);
                    setTotalPages(response.players.total);
                    setPageSize(response.players.per_page);
                    setPage(response.players.current_page);
                }
            }            
        }
        setLoading(false);
    };


    const sendRequestToGetUsers = (values) => {
        setPhone(values.phone);
        setName(values.name);
        setDate(values.date);
        setUserType(values.userType);
        setPageSize(values.pageSize);
        setPage(values.page);
        setUserAction(values.userAction);
        fetchRecords({
            phone: values.phone,
            name:values.name,
            page:values.page,
            pageSize:values.pageSize,
            type:values.userType,
            action:values.userAction,
            date: [
                values.date ? values.date[0] ? makeServerDate(values.date[0]) : null:null,
                values.date ? values.date[1] ? makeServerDate( values.date[1]) : null:null
            ],
        });

    };



    useEffect(() => {
        let start_date= searchParams.get('start_date');
        let end_date= searchParams.get('end_date');
        let userAction= searchParams.get('type');
        sendRequestToGetUsers({
            date:[start_date ? new Date(start_date) : null, end_date ? new Date(end_date) : null],
            userAction:userAction,
        })
        console.log("user useEffect call")
    }, []);

    const formatDate = (dateString) => {
        const options = {year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleDateString("en-US", options)
    }
    const footerValues = () => {
        return (
            <div style={{ display: "flex" }}>
                <p style={{ marginRight: "20px" }}>
                    {t.total_count}: <strong>{totalCount}</strong>
                </p>
            </div>
        );
    }


    const exportAsExcel=()=>{
        APIDownloadUsertFile({
            phone:phone,
            name:name,
            page:page,
            pageSize:pageSize,
            type:userType,
            action:userAction,
            date: [
                date ? date[0] ? makeServerDate(date[0]) : null:null,
                date ? date[1] ? makeServerDate(date[1]) : null:null
            ],
            exportExcel:true,
        });
    }
    const columns = [
        {
            title: t.name,
            dataIndex: 'name',
        },
        {
            title: t.phone,
            dataIndex: 'phone',
        },{
            title: t.userid,
            dataIndex: 'user_id',
        },
        {
            title: t.status,
            dataIndex: 'user_id',
            render: (tag) => ( <Tag color={tag? 'green' : 'volcano'}>{tag? t.active : t.inactive}</Tag>),
        },
        {
            title: t.total+' '+t.deposit,
            dataIndex: 'total_deposit_sum_transaction_amount',
            render: (total) => (addCommasToNumber(total?? 0)) ,

        },
        {
            title: t.total+' '+t.withdraw,
            dataIndex: 'total_withdraw_sum_transaction_amount',
            render: (total) => (addCommasToNumber(total?? 0)) ,
        },
        {
            title: t.last+' '+t.login+' '+t.ip,
            dataIndex: 'latest_login_i_p',
            render: (latest_login_i_p) => latest_login_i_p.ip,
        },
        {
            title: t.signup+' '+t.ip,
            dataIndex: 'signup_i_p',
            render: (signup_i_p) => signup_i_p.ip,
        },
        {
            title: t.register+' '+t.date,
            dataIndex: 'created_at',
            render: (created_at) => formatDate(created_at),
        },
        {
            title: t.last+' '+t.login,
            dataIndex: 'created_at',
            render: (created_at) => formatDate(created_at),
        },
    ];
    return (
<>
           <Card title={t.player+' '+ t.listing} type={"inner"}>
                <Card title={t.filters} type="inner" bordered={true}>
                    <Form name="filterForm" layout="inline" onFinish={sendRequestToGetUsers} >
                        <Form.Item name="date"  label={t.select +' '+t.date}>
                            <RangePicker  />
                        </Form.Item>
                        <Form.Item name={'name'} label={t.name}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={'phone'} label={t.phone}>
                            <Input />
                        </Form.Item>
                        <Form.Item  name={'userType'}  label={t.type} initialValue={userType} >
                            <Select style={{width:'100px'}}>
                                <Select.Option  value="all">{t.all}</Select.Option>
                                <Select.Option value="active">{t.active}</Select.Option>
                                <Select.Option value="inactive">{t.inactive}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item  name={'userAction'}  label={t.action} initialValue={userAction} >
                            <Select style={{width:'100px'}}>
                                <Select.Option  value="all">{t.all}</Select.Option>
                                <Select.Option value="register">{t.register}</Select.Option>
                                <Select.Option value="logins">{t.login}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {t.submit}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={exportAsExcel}>
                                Export
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                <Divider/>

                <Card type="inner" title={t.player} bordered={true}>


                    <Table bordered loading={loading}
                           columns={columns}
                           footer={footerValues}
                           dataSource={dataSourse}
                           pagination={{
                               current:page,
                               pageSize: pageSize,
                               total: totalPages,
                               onChange: (pageNumber, pageSize) => {
                                   sendRequestToGetUsers({
                                       phone: phone,
                                       name:name,
                                       page:pageNumber,
                                       pageSize:pageSize,
                                       userType: userType,
                                       date: date,
                                       action:userAction,
                                   })
                               }
                           }}
                    />
                </Card>
            </Card>
            </> 
                )
}
export default Users

import './App.css';
import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Pages/Login/Login';
import Home from './Pages/Home/Home';
import Users from "./Pages/Users/Users";
import Transactions from "./Pages/Transactions/Transactions";
import LangProvider from "./helpers/Context/LangProvider";
import UserContext from './helpers/Context/user-context';
import UserProvider from "./helpers/Context/UserProvider";
import AuthLayout from './Layout/AuthLayout';


let timeOut = null
function App() {

  const ctx = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem('token_gasv')) {
      ctx.setUser(localStorage.getItem('token_gasv'));
    }
  }, []);

  const AuthRoute = ({ children }) => {
    if (!localStorage.getItem('token_gasv')) {
      return <Navigate to="/login" replace />;
    }
   return children;
  }

  const GuestRoute = ({ children }) => {
    if (localStorage.getItem('token_gasv')) {
      return <Navigate to="/" replace />;
    }
    return children;
  }
  
  return (
    <div className="App">
      <LangProvider>
      <UserProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<GuestRoute><Login/></GuestRoute>} />
        {/*</Routes>*/}

        {/*<Routes>*/}
          <Route path="/" element={    <AuthLayout><AuthRoute><Home/></AuthRoute>     </AuthLayout>} />
          <Route path="/users" element={    <AuthLayout><AuthRoute><Users/></AuthRoute>     </AuthLayout>} />
          <Route path="/transactions" element={    <AuthLayout><AuthRoute><Transactions/></AuthRoute>     </AuthLayout>} />

        </Routes>
      </Router>
      </UserProvider>
      </LangProvider>
    </div>
  );
}

export default App;

import axios from 'axios';
import {Url} from "./CommonHelpers";


const BaseUrl = axios.create({
    baseURL: Url().baseAPI,
});


export default BaseUrl

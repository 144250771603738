import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  en:{
    total:'Total',
    deposit:'Deposit',
    deposits:'Deposits',
    withdraw:'Withdraw',
    player:"Players",
    transactions:"Transactions",
    home:"Home",
    name:'Name',
    phone:'Phone',
    type:'Type',
    english:'English',
    vietnamese:'Vietnamese',
    logout:'Logout',
    reset:'Reset',
    password:'Password',
    settings:'Settings',
    submit:'Submit',
    amount:'Amount',
    filters:'Filters',
    users:'users',
    register:'Register',
    month:'Month',
    today:'Today',
    yesterday:'Yesterday',
    this:'This',
    login:'Login',
    logins:'Login',
    overview:'Overview',
    statistics:'statistics',
    select:'Select',
    date:'Date',
    listing:'Listing',
    ip:'IP',
    signup:'Sign-Up',
    last:'Last',
    status:'Status',
    active:'Active',
    inactive:'Inactive',
    userid:'User_Id',
    new:'New',
    action:'Action',
    all:'All',
    export:'Export',
    total_deposit:'Total Deposit',
    total_withdraw:'Total Withdraw',
    total_amount:'Total Amount',
    total_profit:'Total Profit',
    total_count:'Total Count',
    select_date:'Select Date',
  },

  vn:{
    total:'Tổng',
    deposit:'Nạp tiền',
    deposits:'Nạp tiền',
    withdraw:'Rút tiền',
    player:"Người chơi",
    transactions:"Giao dịch",
    home:"Trang chủ",
    name:'Tên',
    phone:'Số điện thoại',
    type:'Loại',
    english:'Tiếng Anh',
    vietnamese:'Tiếng Việt',
    logout:'Đăng xuất',
    reset:'Làm mới',
    password:'Mật khẩu',
    settings:'Cài đặt',
    submit:'Nộp lên',
    amount:'Số tiền',
    filters:'Lọc',
    users:'Người dùng',
    register:'Người đăng ký',
    month:'Tháng',
    today:'Hôm nay',
    yesterday:'Hôm qua',
    this:'này',
    login:'Đăng nhập',
    logins:'đăng nhập',
    overview:'Tổng quan',
    statistics:'Thống kê',
    select:'Chọn',
    date:'Ngày',
    listing:'Danh sách',
    ip:'IP',
    signup:'Đăng ký',
    last:'Cuối cùng',
    status:'Tích cực',
    active:'Tích cực',
    inactive:'Không hoạt động',
    userid:'Tên người dùng',
    new:'mới',
    action:'Hoạt động',
    all:'tất cả',
    export:'Xuất khẩu',
    total_deposit:'Tổng tiền gửi',
    total_withdraw:'Tổng số tiền rút',
    total_profit:'Tổng lợi nhuận',
    total_amount:'Tổng cộng',
    total_count:'Tổng số',
    select_date:'Chọn ngày',

}
});

export default strings
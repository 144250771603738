import BaseUrl from "./BaseUrl";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import baseUrl from "./BaseUrl";
import {Url} from "./CommonHelpers";

/**
 *
 * @param oldPassword
 * @param password
 * @param confirm
 * @returns {Promise<null|string>}
 * @constructor
 */
export const APIRestPassword = async (oldPassword, password,confirm) => {
  try {
    const res = await BaseUrl.post("/agent_reset_password",{
      oldPassword, password,confirm

    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      },
    });
    console.log('password reset response on helper',res);
    if (res.status===200) {
      return 'success';
    }else if(res.status===402){
      return 'missMatch';
    }else if(res.status===401){
      return 'validation';
    }else if(res.status===500){
      return 'server';
    }else{
      return 'internal_server_error';
    }
  } catch (e) {
    console.log(e);
  }
}
/**
 *
 * @param code
 * @param password
 * @returns {Promise<*|null|string>}
 * @constructor
 */
export const APILoginUser = async (code, password) => {
  try {
    const res = await BaseUrl.post('/agent_login', {
      code, password
    });
    console.log(res);
    if (res.status === 200) {
      if (res.data && res.data.status && res.data.token) {
        return res.data;
      }
    }
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 500) {
        return 'serverError'
      } else if (e.response.status === 422) {
        return 'wrongAgentCode'
      } else if (e.response.status === 402) {
        return 'passwordDoesNotMatched'
      }else if (e.response.status === 401) {
        return 'validationError'
      } else {
        console.log(e)
      }
    }
  }
  return null;
}
/**
 * @param page
 * @returns {Promise<*|null>}
 * @constructor
 */
export const APIUser = async (data) => {
  console.log('APIUser Send Params',data)
  try {
    const res = await BaseUrl.get(`/users`,{
      params: data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`,
      }
    });
    console.log('APIUser Received Response Object',res.data)
    return res.data;

  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        return "TOKEN_EXPIRED";
      }
    }
  }
  return null;
}
/**
 *
 * @param page
 * @returns {Promise<*|null>}
 * @constructor
 */
export const APITransactions = async (data) => {
  console.log('APITransactions Send Params',data)

  try {
    const res = await BaseUrl.get(`/player_transactions`, {
      params:data,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });

    console.log(res)
    return res.data;
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        return "TOKEN_EXPIRED";
      }
    }
  }
  return null;
}
/**
 * @returns {Promise<*|null>}
 * @constructor
 * file download
 */
export const APIDownloadTrabsactionFile = async (data) => {

  const token = localStorage.getItem('token_gasv');
  try {
    const response = await axios.get(Url().baseAPI+'/player_transactions', {
      responseType: 'blob',
      params:data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transaction'+new Date().toLocaleString('en-US')+'.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }

}

export const APIDownloadUsertFile = async (data) => {

  const token = localStorage.getItem('token_gasv');
  try {
    const response = await axios.get(Url().baseAPI+'/users', {
      responseType: 'blob',
      params:data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'PLayers'+new Date().toLocaleString('en-US')+'.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
  }

}

/**
 * @returns {Promise<*|null>}
 * @constructor
 */
export const APIRegisterStats = async () => {
  try {
    const res = await BaseUrl.get("/register_stats", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });
    console.log(res);
    return res.data;
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        localStorage.removeItem('token_gasv');
        return "TOKEN_EXPIRED";
      } else {
        console.log(e)
      }
    }
  }
  return null;
}
/**
 * @returns {Promise<*|null>}
 * @constructor
 */
export const APILoginStats = async () => {
  try {
    const res = await BaseUrl.get("/login_stats", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });
    console.log(res);
    return res.data
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        return null;
      } else {
        console.log(e)
      }
    }
  }
  return null;
}
/**
 * @returns {Promise<*|null>}
 * @constructor
 */
export const APITransactionStats = async () => {
  try {
    const res = await BaseUrl.get("/transaction_stats", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });
    console.log(res);
    return res.data
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        localStorage.removeItem('token_gasv');
        localStorage.removeItem('agent_id');
        return "TOKEN_EXPIRED";
      } else {
        console.log(e)
      }
    }
  }
  return null;
}
export const APIDepositStats = async () => {
  try {
    const res = await BaseUrl.get("/deposit_stats", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });
    console.log(res);
    return res.data
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        localStorage.removeItem('token_gasv');
        localStorage.removeItem('agent_id');
      } else {
        console.log(e)
      }
    }
  }
  return null;
}
export const APIWithdrawStats = async () => {
  try {
    const res = await BaseUrl.get("/withdraw_stats", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token_gasv')}`
      }
    });
    console.log(res);
    return res.data
  } catch (e) {
    console.log(e)
    if (e.response) {
      if (e.response.status === 401) {
        localStorage.removeItem('token_gasv');
        localStorage.removeItem('agent_id');
      } else {
        console.log(e)
      }
    }
  }
  return null;
}

export const addCommasToNumber = val => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const makeServerDate = date => {
  return new Date(date.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"})).toLocaleString("en-US")
}
export const removeCommasFromNumber = val => {
  return val.toString().replace(/,/g, "");
}
export const Unauthenticated = val => {
  const navigate = useNavigate()
  if(val==='unauthenticated'){
    localStorage.removeItem('token_gasv');
    localStorage.removeItem('agent_id');
    navigate('/login')
  }else {
    return val
  }
}
 import {HomeOutlined,UserOutlined, TransactionOutlined} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import TopNavBar from './TopNavBar';
import {Link} from "react-router-dom";
import langContext from "../helpers/Context/lang-context";
const { Header, Sider, Content } = Layout;

const AuthLayout = ({children}) => {
    const [collapsed, setCollapsed] = useState(false);
    const [current, setCurrent] = useState();
    const ctx=useContext(langContext)
    const t= ctx.t;
    const { token: { colorBgContainer }, } = theme.useToken();



    const onClick = (e) => {
        console.log(e.key)
        setCurrent(e.key);
    };


    useEffect(()=> {
        console.log(current)
    },[current]);







    return (
        <Layout style={{height:"100%"}}>
          <Sider trigger={null} collapsible collapsed={collapsed}>
              <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  onClick={onClick}
                  selectedKeys={[current]}
                  items={[
                      {
                          key: '1',
                          icon: <HomeOutlined/>,
                          label: <Link  to={'/'}>{t.home}</Link>,
                      },
                      {
                          key: '2',
                          icon: <UserOutlined/>,
                          label: <Link  to={'/users'}>{t.player}</Link>,
                      },
                      {
                          key: '3',
                          icon: <TransactionOutlined/>,
                          label: <Link to={'/transactions'}>{t.transactions}</Link>,
                      },
                  ]}
              />
          </Sider>
          <Layout className="site-layout" style={{height:"100%"}}>
           <TopNavBar collapsed={collapsed} setCollapsed={setCollapsed}  />
            <Content style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
                background: '#ECECEC',
              }} >
              {children}
            </Content>
          </Layout>
        </Layout>
      );
}

export default AuthLayout
import React, {useContext,useEffect, useState} from 'react'
import AuthLayout from '../../Layout/AuthLayout'
import dayjs from 'dayjs';
import {
    UploadOutlined,
    DownloadOutlined,
    CopyOutlined
} from '@ant-design/icons';
import {Row, Button, Input, Space, Table, Card} from 'antd';
import StatisticCard from "../../Components/Cards/StatisticCard";
import CustomCard from "../../Components/Cards/CustomCard";
import langContext from "../../helpers/Context/lang-context";
import {
    addCommasToNumber,
    APIDepositStats,
    APILoginStats, APIRegisterStats,
    APITransactionStats,
    APIWithdrawStats,
} from "../../helpers/APIs/UserAPIs";
import userContext from "../../helpers/Context/user-context";
import {useNavigate} from "react-router-dom";
import BaseUrl from "../../helpers/APIs/BaseUrl";
import {Url} from "../../helpers/APIs/CommonHelpers";

const {Search} = Input;

const Home = () => {
    const [loginStats, setLoginState] = useState();
    const [transactionState, setTransactionState] = useState();
    const [agentId, setAgentId] = useState(localStorage.getItem('agent_id'));
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate=useNavigate();
    const ctx=useContext(langContext)
    const ctx_user=useContext(userContext)
    const t=ctx.t;
    console.log('Url.baseWEB1',Url.baseWEB1)

    const copyLinkTwo = (agentId) => {
        navigator.clipboard.writeText(Url().baseWEB1 + agentId);
    }
    const copyLinkOne = (agentId) => {
        navigator.clipboard.writeText(Url().baseWEB2 + agentId);
    }

    const getStats = async () => {

        setLoading(true);
        const registerStats = await APIRegisterStats();
        if (registerStats == "TOKEN_EXPIRED") {
            ctx_user.setUser('');
            navigate('/login');
        }else if(registerStats == null){
            //TODO: SHOW CUSTOM ERROR MESSAGE
        }else {
            const loginStats = await APILoginStats();
            const transactionStats = await APITransactionStats();
            const depositStats = await APIDepositStats();
            const withdrawStats = await APIWithdrawStats();

            setLoginState(registerStats);
            setTransactionState(transactionStats);
            setDataSource([registerStats,loginStats,depositStats,withdrawStats]);
            setLoading(false);
        }


    };
    useEffect(() => {
        getStats()
    }, []);



    const sharedOnCell = (_, index) => {
        if (index === 1) {
            return {
                colSpan: 0,
            };
        }
        return {};
    };
    // Get start and end dates for yesterday
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStart = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0).toLocaleString();
    const yesterdayEnd = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59).toLocaleString();

    // Get start and end dates for today
    const today = new Date();
    const todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).toLocaleString();
    const todayEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59).toLocaleString();

    // Get start and end dates for current month
    const currentMonth = new Date();
    const currentMonthStart = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1, 0, 0, 0).toLocaleString();
    const currentMonthEnd = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0, 23, 59, 59).toLocaleString();
    const paramsForFilter = (queryString, type) => {
        let startDate, endDate;

        switch (type) {
            case 'today':
                startDate = todayStart;
                endDate = todayEnd;
                break;
            case 'yesterday':
                startDate = yesterdayStart;
                endDate = yesterdayEnd;
                break;
            case 'month':
                startDate = currentMonthStart;
                endDate = currentMonthEnd;
                break;
            default:
                throw new Error(`Unexpected type: ${type}`);
        }

        switch (queryString) {
            case 'Withdraw':
                return `/transactions?start_date=${startDate}&end_date=${endDate}&type=withdraw`;
            case 'Deposits':
                return `/transactions?start_date=${startDate}&end_date=${endDate}&type=deposits`;
            case 'Register':
                return `/users?start_date=${startDate}&end_date=${endDate}&type=register`;
            case 'Logins':
                return `/users?start_date=${startDate}&end_date=${endDate}&type=logins`;
            default:
                throw new Error(`Unexpected queryString: ${queryString}`);
        }
    }


    const columns = [
        {
            title: t.type,
            dataIndex: 'type',
            rowScope: 'row',
            render: (text)=>(<a href={text === 'Register' ||text === 'Logins' ? '/users' : text === 'Deposits' ||text === 'Withdraw' ? '/transactions' : ''}>{t[text.toLowerCase()] }</a>),
        },
        {
            title: t.today,
            dataIndex: 'today',
            render: (text, record )=>(<a href={paramsForFilter(record.type,'today')}>{addCommasToNumber(text)}</a>),
        },
        {
            title: t.yesterday,
            dataIndex: 'yesterday',
            render: (text, record )=>(<a href={paramsForFilter(record.type,'yesterday')}>{addCommasToNumber(text)}</a>),

        },
        {
            title: t.this+' '+ t.month,
            dataIndex: 'month',
            render: (text, record )=>(<a href={paramsForFilter(record.type,'month')}>{addCommasToNumber(text)}</a>),

        },
        {
            title: t.total,
            dataIndex: 'total',
            render: (text, record )=>(<a href={record.type === 'Register' ||record.type === 'Logins' ? '/users' : record.type === 'Deposits' ||record.type === 'Withdraw' ? '/transactions' : ''}>{addCommasToNumber(text)}</a>),

        },
    ];


    return (
            <div style={{background: '#ECECEC', padding: '30px'}}>
                <Row gutter={16} style={{ justifyContent: 'space-between'}}>
                    <Space.Compact span={6} style={{width: '25%',}}>
                        <Input defaultValue={Url().baseWEB1 + agentId}/>
                        <Button title={'Link 1'} onClick={() => copyLinkOne(agentId)}><CopyOutlined/></Button>

                    </Space.Compact>

                    <Space.Compact span={6} style={{
                        width: '25%',
                    }}>
                        <Input defaultValue={Url().baseWEB2 + agentId}/>
                        <Button title={'Link 2'} onClick={() => copyLinkTwo(agentId)}><CopyOutlined/></Button>
                    </Space.Compact>
                </Row>
                <hr style={{margin: '24px', borderColor: '#ffffff4d'}}/>
                <Row gutter={16} >
                    <CustomCard value={transactionState && transactionState['total_deposit']} title={t.total+' '+t.deposit} icon={DownloadOutlined} type={'deposit'}/>
                    <CustomCard value={transactionState && transactionState['month_deposit']} title={t.this+' '+t.month+' '+t.deposit} icon={DownloadOutlined} type={'deposit'}/>
                    <CustomCard value={transactionState && transactionState['month_withdraw']} title={t.this+' '+t.month+' '+t.withdraw} icon={UploadOutlined} type={'withdraw'}/>
                    <CustomCard value={transactionState && transactionState['total_withdraw']} title={t.total+' '+t.withdraw} icon={UploadOutlined} type={'withdraw'}/>
                </Row>
                <hr style={{margin: '24px', borderColor: '#ffffff4d'}}/>
                <Row gutter={16} >
                    <StatisticCard value={loginStats && loginStats['today']} title={t.today+' '+t.register+' '+t.users} suffix={t.users} />
                    <StatisticCard value={loginStats && loginStats['yesterday']} title={t.yesterday+' '+t.register+' '+t.users} suffix={t.users}/>
                    <StatisticCard value={loginStats && loginStats['month']} title={t.this+' '+t.month+' '+t.register+' '+t.users} suffix={t.users}/>
                    <StatisticCard value={loginStats && loginStats['total']} title={t.total+' '+t.register+' '+t.users} suffix={t.users}/>
                </Row>
                <hr style={{margin: '24px', borderColor: '#ffffff4d'}}/>
                <Card title="Overview/Statistics" type={'inner'} bordered={true}>
                    <Table columns={columns} dataSource={dataSource} bordered loading={loading} pagination={false}/>
                </Card>
            </div>
    )
}

export default Home

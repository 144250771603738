import {Card, Col} from "antd";
import {DownCircleOutlined, DownloadOutlined, UploadOutlined} from "@ant-design/icons";
import React from "react";
import styles from './CustomCard.module.css'
const {Meta} = Card;
const CustomCard =(props)=>{
    const Icon=props.icon;
     const addCommasToNumber = val => {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
     const color= (props.type=='deposit')?styles.depositIcon:styles.withdrawIcon
    return(
        <Col span={6}>
            <Card

                hoverable
                title={props.title} bordered={true}>
                <Meta
                    avatar={<Icon className={color} />}
                    title={props.value && addCommasToNumber(props.value) } />
            </Card>
        </Col>
    )
}
export default CustomCard;
import {useContext, useEffect, useState} from 'react';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { APILoginUser } from '../../helpers/APIs/UserAPIs';
import{UserOutlined,LockOutlined } from '@ant-design/icons';
import styles from './Login.module.css'
import { useNavigate } from 'react-router-dom';
import userContext from "../../helpers/Context/user-context";

const Login = () => {
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('') 
    const [error, setError] = useState('')
    const { Title } = Typography;
    const navigate = useNavigate()
    const ctx_user=useContext(userContext)


    const handleSubmit = async () => {

        const x = await APILoginUser(code, password);

        if(x && x.token){
            localStorage.setItem('token_gasv', x.token)
            localStorage.setItem('agent_id', x.user.agent_id)
            navigate('/');
        }else if(x==='wrongAgentCode'){
            setError('Wrong Agent Code')
        }else if(x==='passwordDoesNotMatched'){
            setError('Wrong Password')
        }else if(x==='passwordDoesNotMatched'){
            setError('Wrong Password')
        }else{
            setError('Something Went Wrong ')
        }

    };
    useEffect(()=>{
        console.log(ctx_user.user)
    },[ctx_user.user])
    return (
        <div className={styles.formWrapper}> 
            <Form onFinish={handleSubmit} className={styles.loginForm}>
                <Form.Item style={{textAlign:'center'}}>
                    <Title level={1}>Login</Title>
                </Form.Item>
                <Form.Item>
                    <Input
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>}
                        placeholder="Agent Code"
                        type="text"
                        size="large"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required/>

                </Form.Item>
                <Form.Item>
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                        size="large"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Item>
                <p style={{color: "red" ,textAlign:"center"}} >{error}</p>
                <Form.Item>
                {/*<div className={styles.rememberWrapper}>*/}
                {/*    <Checkbox>Remember me</Checkbox>*/}
                {/*    <a href="">Forgot password?</a>*/}
                {/*    </div>*/}
                </Form.Item>    
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width:'100%'}} size="large">
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Login
